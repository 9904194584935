import _buffer from "buffer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Buffer = _buffer.Buffer;

/*global Buffer*/
// Named constants with unique integer values
var C = {}; // Tokens

var LEFT_BRACE = C.LEFT_BRACE = 1;
var RIGHT_BRACE = C.RIGHT_BRACE = 2;
var LEFT_BRACKET = C.LEFT_BRACKET = 3;
var RIGHT_BRACKET = C.RIGHT_BRACKET = 4;
var COLON = C.COLON = 5;
var COMMA = C.COMMA = 6;
var TRUE = C.TRUE = 7;
var FALSE = C.FALSE = 8;
var NULL = C.NULL = 9;
var STRING = C.STRING = 10;
var NUMBER = C.NUMBER = 11; // Tokenizer States

var START = C.START = 17;
var STOP = C.STOP = 18;
var TRUE1 = C.TRUE1 = 33;
var TRUE2 = C.TRUE2 = 34;
var TRUE3 = C.TRUE3 = 35;
var FALSE1 = C.FALSE1 = 49;
var FALSE2 = C.FALSE2 = 50;
var FALSE3 = C.FALSE3 = 51;
var FALSE4 = C.FALSE4 = 52;
var NULL1 = C.NULL1 = 65;
var NULL2 = C.NULL2 = 66;
var NULL3 = C.NULL3 = 67;
var NUMBER1 = C.NUMBER1 = 81;
var NUMBER3 = C.NUMBER3 = 83;
var STRING1 = C.STRING1 = 97;
var STRING2 = C.STRING2 = 98;
var STRING3 = C.STRING3 = 99;
var STRING4 = C.STRING4 = 100;
var STRING5 = C.STRING5 = 101;
var STRING6 = C.STRING6 = 102; // Parser States

var VALUE = C.VALUE = 113;
var KEY = C.KEY = 114; // Parser Modes

var OBJECT = C.OBJECT = 129;
var ARRAY = C.ARRAY = 130; // Character constants

var BACK_SLASH = "\\".charCodeAt(0);
var FORWARD_SLASH = "/".charCodeAt(0);
var BACKSPACE = "\b".charCodeAt(0);
var FORM_FEED = "\f".charCodeAt(0);
var NEWLINE = "\n".charCodeAt(0);
var CARRIAGE_RETURN = "\r".charCodeAt(0);
var TAB = "\t".charCodeAt(0);
var STRING_BUFFER_SIZE = 64 * 1024;

function Parser() {
  (this || _global).tState = START;
  (this || _global).value = undefined;
  (this || _global).string = undefined; // string data

  (this || _global).stringBuffer = Buffer.alloc ? Buffer.alloc(STRING_BUFFER_SIZE) : new Buffer(STRING_BUFFER_SIZE);
  (this || _global).stringBufferOffset = 0;
  (this || _global).unicode = undefined; // unicode escapes

  (this || _global).highSurrogate = undefined;
  (this || _global).key = undefined;
  (this || _global).mode = undefined;
  (this || _global).stack = [];
  (this || _global).state = VALUE;
  (this || _global).bytes_remaining = 0; // number of bytes remaining in multi byte utf8 char to read after split boundary

  (this || _global).bytes_in_sequence = 0; // bytes in multi byte utf8 char to read

  (this || _global).temp_buffs = {
    "2": new Buffer(2),
    "3": new Buffer(3),
    "4": new Buffer(4)
  }; // for rebuilding chars split before boundary is reached
  // Stream offset

  (this || _global).offset = -1;
} // Slow code to string converter (only used when throwing syntax errors)


Parser.toknam = function (code) {
  var keys = Object.keys(C);

  for (var i = 0, l = keys.length; i < l; i++) {
    var key = keys[i];

    if (C[key] === code) {
      return key;
    }
  }

  return code && "0x" + code.toString(16);
};

var proto = Parser.prototype;

proto.onError = function (err) {
  throw err;
};

proto.charError = function (buffer, i) {
  (this || _global).tState = STOP;
  this.onError(new Error("Unexpected " + JSON.stringify(String.fromCharCode(buffer[i])) + " at position " + i + " in state " + Parser.toknam((this || _global).tState)));
};

proto.appendStringChar = function (char) {
  if ((this || _global).stringBufferOffset >= STRING_BUFFER_SIZE) {
    (this || _global).string += (this || _global).stringBuffer.toString("utf8");
    (this || _global).stringBufferOffset = 0;
  }

  (this || _global).stringBuffer[(this || _global).stringBufferOffset++] = char;
};

proto.appendStringBuf = function (buf, start, end) {
  var size = buf.length;

  if (typeof start === "number") {
    if (typeof end === "number") {
      if (end < 0) {
        // adding a negative end decreeses the size
        size = buf.length - start + end;
      } else {
        size = end - start;
      }
    } else {
      size = buf.length - start;
    }
  }

  if (size < 0) {
    size = 0;
  }

  if ((this || _global).stringBufferOffset + size > STRING_BUFFER_SIZE) {
    (this || _global).string += (this || _global).stringBuffer.toString("utf8", 0, (this || _global).stringBufferOffset);
    (this || _global).stringBufferOffset = 0;
  }

  buf.copy((this || _global).stringBuffer, (this || _global).stringBufferOffset, start, end);
  (this || _global).stringBufferOffset += size;
};

proto.write = function (buffer) {
  if (typeof buffer === "string") buffer = new Buffer(buffer);
  var n;

  for (var i = 0, l = buffer.length; i < l; i++) {
    if ((this || _global).tState === START) {
      n = buffer[i];
      (this || _global).offset++;

      if (n === 123) {
        this.onToken(LEFT_BRACE, "{"); // {
      } else if (n === 125) {
        this.onToken(RIGHT_BRACE, "}"); // }
      } else if (n === 91) {
        this.onToken(LEFT_BRACKET, "["); // [
      } else if (n === 93) {
        this.onToken(RIGHT_BRACKET, "]"); // ]
      } else if (n === 58) {
        this.onToken(COLON, ":"); // :
      } else if (n === 44) {
        this.onToken(COMMA, ","); // ,
      } else if (n === 116) {
        (this || _global).tState = TRUE1; // t
      } else if (n === 102) {
        (this || _global).tState = FALSE1; // f
      } else if (n === 110) {
        (this || _global).tState = NULL1; // n
      } else if (n === 34) {
        // "
        (this || _global).string = "";
        (this || _global).stringBufferOffset = 0;
        (this || _global).tState = STRING1;
      } else if (n === 45) {
        (this || _global).string = "-";
        (this || _global).tState = NUMBER1; // -
      } else {
        if (n >= 48 && n < 64) {
          // 1-9
          (this || _global).string = String.fromCharCode(n);
          (this || _global).tState = NUMBER3;
        } else if (n === 32 || n === 9 || n === 10 || n === 13) {// whitespace
        } else {
          return this.charError(buffer, i);
        }
      }
    } else if ((this || _global).tState === STRING1) {
      // After open quote
      n = buffer[i]; // get current byte from buffer
      // check for carry over of a multi byte char split between data chunks
      // & fill temp buffer it with start of this data chunk up to the boundary limit set in the last iteration

      if ((this || _global).bytes_remaining > 0) {
        for (var j = 0; j < (this || _global).bytes_remaining; j++) {
          (this || _global).temp_buffs[(this || _global).bytes_in_sequence][(this || _global).bytes_in_sequence - (this || _global).bytes_remaining + j] = buffer[j];
        }

        this.appendStringBuf((this || _global).temp_buffs[(this || _global).bytes_in_sequence]);
        (this || _global).bytes_in_sequence = (this || _global).bytes_remaining = 0;
        i = i + j - 1;
      } else if ((this || _global).bytes_remaining === 0 && n >= 128) {
        // else if no remainder bytes carried over, parse multi byte (>=128) chars one at a time
        if (n <= 193 || n > 244) {
          return this.onError(new Error("Invalid UTF-8 character at position " + i + " in state " + Parser.toknam((this || _global).tState)));
        }

        if (n >= 194 && n <= 223) (this || _global).bytes_in_sequence = 2;
        if (n >= 224 && n <= 239) (this || _global).bytes_in_sequence = 3;
        if (n >= 240 && n <= 244) (this || _global).bytes_in_sequence = 4;

        if ((this || _global).bytes_in_sequence + i > buffer.length) {
          // if bytes needed to complete char fall outside buffer length, we have a boundary split
          for (var k = 0; k <= buffer.length - 1 - i; k++) {
            (this || _global).temp_buffs[(this || _global).bytes_in_sequence][k] = buffer[i + k]; // fill temp buffer of correct size with bytes available in this chunk
          }

          (this || _global).bytes_remaining = i + (this || _global).bytes_in_sequence - buffer.length;
          i = buffer.length - 1;
        } else {
          this.appendStringBuf(buffer, i, i + (this || _global).bytes_in_sequence);
          i = i + (this || _global).bytes_in_sequence - 1;
        }
      } else if (n === 34) {
        (this || _global).tState = START;
        (this || _global).string += (this || _global).stringBuffer.toString("utf8", 0, (this || _global).stringBufferOffset);
        (this || _global).stringBufferOffset = 0;
        this.onToken(STRING, (this || _global).string);
        (this || _global).offset += Buffer.byteLength((this || _global).string, "utf8") + 1;
        (this || _global).string = undefined;
      } else if (n === 92) {
        (this || _global).tState = STRING2;
      } else if (n >= 32) {
        this.appendStringChar(n);
      } else {
        return this.charError(buffer, i);
      }
    } else if ((this || _global).tState === STRING2) {
      // After backslash
      n = buffer[i];

      if (n === 34) {
        this.appendStringChar(n);
        (this || _global).tState = STRING1;
      } else if (n === 92) {
        this.appendStringChar(BACK_SLASH);
        (this || _global).tState = STRING1;
      } else if (n === 47) {
        this.appendStringChar(FORWARD_SLASH);
        (this || _global).tState = STRING1;
      } else if (n === 98) {
        this.appendStringChar(BACKSPACE);
        (this || _global).tState = STRING1;
      } else if (n === 102) {
        this.appendStringChar(FORM_FEED);
        (this || _global).tState = STRING1;
      } else if (n === 110) {
        this.appendStringChar(NEWLINE);
        (this || _global).tState = STRING1;
      } else if (n === 114) {
        this.appendStringChar(CARRIAGE_RETURN);
        (this || _global).tState = STRING1;
      } else if (n === 116) {
        this.appendStringChar(TAB);
        (this || _global).tState = STRING1;
      } else if (n === 117) {
        (this || _global).unicode = "";
        (this || _global).tState = STRING3;
      } else {
        return this.charError(buffer, i);
      }
    } else if ((this || _global).tState === STRING3 || (this || _global).tState === STRING4 || (this || _global).tState === STRING5 || (this || _global).tState === STRING6) {
      // unicode hex codes
      n = buffer[i]; // 0-9 A-F a-f

      if (n >= 48 && n < 64 || n > 64 && n <= 70 || n > 96 && n <= 102) {
        (this || _global).unicode += String.fromCharCode(n);

        if ((this || _global).tState++ === STRING6) {
          var intVal = parseInt((this || _global).unicode, 16);
          (this || _global).unicode = undefined;

          if ((this || _global).highSurrogate !== undefined && intVal >= 56320 && intVal < 57343 + 1) {
            //<56320,57343> - lowSurrogate
            this.appendStringBuf(new Buffer(String.fromCharCode((this || _global).highSurrogate, intVal)));
            (this || _global).highSurrogate = undefined;
          } else if ((this || _global).highSurrogate === undefined && intVal >= 55296 && intVal < 56319 + 1) {
            //<55296,56319> - highSurrogate
            (this || _global).highSurrogate = intVal;
          } else {
            if ((this || _global).highSurrogate !== undefined) {
              this.appendStringBuf(new Buffer(String.fromCharCode((this || _global).highSurrogate)));
              (this || _global).highSurrogate = undefined;
            }

            this.appendStringBuf(new Buffer(String.fromCharCode(intVal)));
          }

          (this || _global).tState = STRING1;
        }
      } else {
        return this.charError(buffer, i);
      }
    } else if ((this || _global).tState === NUMBER1 || (this || _global).tState === NUMBER3) {
      n = buffer[i];

      switch (n) {
        case 48: // 0

        case 49: // 1

        case 50: // 2

        case 51: // 3

        case 52: // 4

        case 53: // 5

        case 54: // 6

        case 55: // 7

        case 56: // 8

        case 57: // 9

        case 46: // .

        case 101: // e

        case 69: // E

        case 43: // +

        case 45:
          // -
          (this || _global).string += String.fromCharCode(n);
          (this || _global).tState = NUMBER3;
          break;

        default:
          (this || _global).tState = START;
          var result = Number((this || _global).string);

          if (isNaN(result)) {
            return this.charError(buffer, i);
          }

          if ((this || _global).string.match(/[0-9]+/) == (this || _global).string && result.toString() != (this || _global).string) {
            // Long string of digits which is an ID string and not valid and/or safe JavaScript integer Number
            this.onToken(STRING, (this || _global).string);
          } else {
            this.onToken(NUMBER, result);
          }

          (this || _global).offset += (this || _global).string.length - 1;
          (this || _global).string = undefined;
          i--;
          break;
      }
    } else if ((this || _global).tState === TRUE1) {
      // r
      if (buffer[i] === 114) {
        (this || _global).tState = TRUE2;
      } else {
        return this.charError(buffer, i);
      }
    } else if ((this || _global).tState === TRUE2) {
      // u
      if (buffer[i] === 117) {
        (this || _global).tState = TRUE3;
      } else {
        return this.charError(buffer, i);
      }
    } else if ((this || _global).tState === TRUE3) {
      // e
      if (buffer[i] === 101) {
        (this || _global).tState = START;
        this.onToken(TRUE, true);
        (this || _global).offset += 3;
      } else {
        return this.charError(buffer, i);
      }
    } else if ((this || _global).tState === FALSE1) {
      // a
      if (buffer[i] === 97) {
        (this || _global).tState = FALSE2;
      } else {
        return this.charError(buffer, i);
      }
    } else if ((this || _global).tState === FALSE2) {
      // l
      if (buffer[i] === 108) {
        (this || _global).tState = FALSE3;
      } else {
        return this.charError(buffer, i);
      }
    } else if ((this || _global).tState === FALSE3) {
      // s
      if (buffer[i] === 115) {
        (this || _global).tState = FALSE4;
      } else {
        return this.charError(buffer, i);
      }
    } else if ((this || _global).tState === FALSE4) {
      // e
      if (buffer[i] === 101) {
        (this || _global).tState = START;
        this.onToken(FALSE, false);
        (this || _global).offset += 4;
      } else {
        return this.charError(buffer, i);
      }
    } else if ((this || _global).tState === NULL1) {
      // u
      if (buffer[i] === 117) {
        (this || _global).tState = NULL2;
      } else {
        return this.charError(buffer, i);
      }
    } else if ((this || _global).tState === NULL2) {
      // l
      if (buffer[i] === 108) {
        (this || _global).tState = NULL3;
      } else {
        return this.charError(buffer, i);
      }
    } else if ((this || _global).tState === NULL3) {
      // l
      if (buffer[i] === 108) {
        (this || _global).tState = START;
        this.onToken(NULL, null);
        (this || _global).offset += 3;
      } else {
        return this.charError(buffer, i);
      }
    }
  }
};

proto.onToken = function (token, value) {// Override this to get events
};

proto.parseError = function (token, value) {
  (this || _global).tState = STOP;
  this.onError(new Error("Unexpected " + Parser.toknam(token) + (value ? "(" + JSON.stringify(value) + ")" : "") + " in state " + Parser.toknam((this || _global).state)));
};

proto.push = function () {
  (this || _global).stack.push({
    value: (this || _global).value,
    key: (this || _global).key,
    mode: (this || _global).mode
  });
};

proto.pop = function () {
  var value = (this || _global).value;

  var parent = (this || _global).stack.pop();

  (this || _global).value = parent.value;
  (this || _global).key = parent.key;
  (this || _global).mode = parent.mode;
  this.emit(value);

  if (!(this || _global).mode) {
    (this || _global).state = VALUE;
  }
};

proto.emit = function (value) {
  if ((this || _global).mode) {
    (this || _global).state = COMMA;
  }

  this.onValue(value);
};

proto.onValue = function (value) {// Override me
};

proto.onToken = function (token, value) {
  if ((this || _global).state === VALUE) {
    if (token === STRING || token === NUMBER || token === TRUE || token === FALSE || token === NULL) {
      if ((this || _global).value) {
        (this || _global).value[(this || _global).key] = value;
      }

      this.emit(value);
    } else if (token === LEFT_BRACE) {
      this.push();

      if ((this || _global).value) {
        (this || _global).value = (this || _global).value[(this || _global).key] = {};
      } else {
        (this || _global).value = {};
      }

      (this || _global).key = undefined;
      (this || _global).state = KEY;
      (this || _global).mode = OBJECT;
    } else if (token === LEFT_BRACKET) {
      this.push();

      if ((this || _global).value) {
        (this || _global).value = (this || _global).value[(this || _global).key] = [];
      } else {
        (this || _global).value = [];
      }

      (this || _global).key = 0;
      (this || _global).mode = ARRAY;
      (this || _global).state = VALUE;
    } else if (token === RIGHT_BRACE) {
      if ((this || _global).mode === OBJECT) {
        this.pop();
      } else {
        return this.parseError(token, value);
      }
    } else if (token === RIGHT_BRACKET) {
      if ((this || _global).mode === ARRAY) {
        this.pop();
      } else {
        return this.parseError(token, value);
      }
    } else {
      return this.parseError(token, value);
    }
  } else if ((this || _global).state === KEY) {
    if (token === STRING) {
      (this || _global).key = value;
      (this || _global).state = COLON;
    } else if (token === RIGHT_BRACE) {
      this.pop();
    } else {
      return this.parseError(token, value);
    }
  } else if ((this || _global).state === COLON) {
    if (token === COLON) {
      (this || _global).state = VALUE;
    } else {
      return this.parseError(token, value);
    }
  } else if ((this || _global).state === COMMA) {
    if (token === COMMA) {
      if ((this || _global).mode === ARRAY) {
        (this || _global).key++;
        (this || _global).state = VALUE;
      } else if ((this || _global).mode === OBJECT) {
        (this || _global).state = KEY;
      }
    } else if (token === RIGHT_BRACKET && (this || _global).mode === ARRAY || token === RIGHT_BRACE && (this || _global).mode === OBJECT) {
      this.pop();
    } else {
      return this.parseError(token, value);
    }
  } else {
    return this.parseError(token, value);
  }
};

Parser.C = C;
exports = Parser;
export default exports;